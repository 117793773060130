import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledCard = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
  width: ${({ wide }) => wide || '290px'};
  max-width: 100%;
  height: 100%;
  a {
    text-decoration: none;
  }
  .gatsby-image-wrapper {
    max-width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
  }
  .imageContainer {
    width: ${({ wide }) => wide || '290px'};
    max-width: 100%;
    border-radius: 10px;
    position: relative;
    height: 200px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
  }
  h3 {
    margin-top: 0;
    margin-bottom: 10px;
    z-index: 2;
    position: ${({ clickable }) => (clickable ? 'absolute' : 'relative')};
    color: ${({ clickable }) => (clickable ? '#fff' : '#333')};
    bottom: 0;
    padding: ${({ clickable }) => (clickable ? '10px' : '0')};
  }
  .content {
    flex-grow: 1;
    padding: ${({ clickable }) => (clickable ? '20px 20px 30px 20px' : '10px')};
    text-decoration: none;
    h4 {
      font-size: 16px;
    }
  }
  svg {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
`;

function Card({ title, subtitle, body, image, clickable, link, width }) {
  const ref = clickable ? (
    <>
      <div className="imageContainer">
        {image && image}
        {title && <h3>{title}</h3>}
      </div>
      <div className="content">
        {subtitle && <h4>{subtitle}</h4>}
        {body && <div>{body}</div>}
        {clickable && (
          <svg
            aria-hidden="true"
            data-prefix="fas"
            data-icon="arrow-circle-right"
            className="svg-inline--fa fa-arrow-circle-right fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="15px"
          >
            <path
              fill="#00600F"
              d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z"
            ></path>
          </svg>
        )}
      </div>
    </>
  ) : (
    <>
      {image && image}
      <div className="content">
        {title && <h3>{title}</h3>}
        {subtitle && <h4>{subtitle}</h4>}
        {body && <div>{body}</div>}
      </div>
    </>
  );

  return link ? (
    <StyledCard clickable={clickable} wide={width}>
      <Link to={link}>{ref}</Link>
    </StyledCard>
  ) : (
    <StyledCard wide={width}>{ref}</StyledCard>
  );
}

export default Card;
