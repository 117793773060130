import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image/withIEPolyfill';

import styled from 'styled-components';

import 'moment/locale/nl-be';
import moment from 'moment';
import Button from './UI/Button';
// import accessTime from "../images/accessTime.svg";

const StyledCard = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.125);
  max-width: 100%;
  margin: 20px 0;
  display: flex;
  background-color: #fff;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  a.gameReviewImage {
    .gatsby-image-wrapper {
      width: 250px;
      height: 100%;
      @media (max-width: 1200px) {
        width: 100%;
        height: 150px;
      }
      img {
        object-fit: cover;
      }
    }
  }
  .cardWrapper {
    padding: 10px 20px;
  }
  .cardTitle {
    font-weight: 500;
    margin-bottom: 0.75rem;
    margin-top: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
  .time {
    font-size: 20px;
    margin: 0 5px;
  }
  .cardBottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .cardBottom p {
    margin: 0;
  }
`;

const GameReviewCard = ({ image, title, excerpt, url, date }) => {
  moment.locale('nl-be');

  return (
    <StyledCard className="gameReviewCard">
      <Link to={url} aria-label={title} className="gameReviewImage">
        {image && image.fluid && (
          <Image fluid={image.fluid} objectFit="cover" objectPosition="50% 50%" />
        )}
      </Link>
      <div className="cardWrapper">
        <div className="cardContent">
          <h5 className="cardTitle">{title}</h5>
          {excerpt && <p dangerouslySetInnerHTML={{ __html: excerpt }} />}
        </div>
        <div className="cardBottom">
          <Link to={url}>
            <Button>Lees verder</Button>
          </Link>
          <p style={{ fontSize: '14px' }}>
            <svg
              className="time"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path>
            </svg>
            {moment(date).format('dd DD/MM')}
          </p>
        </div>
      </div>
    </StyledCard>
  );
};

export default GameReviewCard;
