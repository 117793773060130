import React, { Component } from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

export default class Tweets extends Component {
  render() {
    return (
      <TwitterTimelineEmbed
        linkColor="#6abf69"
        borderColor="#00600F"
        noBorders
        noHeader
        noFooter
        sourceType="profile"
        screenName="oxacobbc"
        options={{
          height: 800,
          tweetLimit: 3,
        }}
      />
    );
  }
}
