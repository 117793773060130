import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import 'moment/locale/nl-be';
import moment from 'moment';

import '../stylesheets/heroMatches.sass';
import prev from '../images/prev.svg';
import next from '../images/next.svg';

export default class heroMatches extends Component {
  state = {
    games: null,
    gameInView: null,
  };

  componentDidMount() {
    axios(
      'https://vblcb.wisseq.eu/VBLCB_WebService/data/TeamMatchesByGuid?teamguid=BVBL1189HSE%20%201'
    )
      .then(res => res.data)
      .then(games =>
        games.map(game => {
          game.date = moment(`${game.datumString} ${game.beginTijd}`, 'DD-MM-YYYY HH.mm');
          return game;
        })
      )
      .then(games => games.sort((currGame, nextGame) => (currGame.date > nextGame.date ? 1 : -1)))
      .then(gamesWithDate => {
        const current = moment();
        const gameToShow = gamesWithDate.filter(game => game.date.isSameOrAfter(current, 'day'))[0];
        this.setState({
          games: gamesWithDate,
          gameInView: gameToShow,
        });
      })
      .catch(err => console.log(err));
  }

  updateGameView = direction => {
    const currentIndex = this.state.games.indexOf(this.state.gameInView);
    if (direction === 'next' && this.state.games[currentIndex + 1]) {
      const nextGame = this.state.games[currentIndex + 1];
      this.setState({ gameInView: nextGame });
    } else if (direction === 'prev' && this.state.games[currentIndex - 1]) {
      const prevGame = this.state.games[currentIndex - 1];
      this.setState({ gameInView: prevGame });
    }
  };

  render() {
    if (this.state.gameInView) {
      const { gameInView } = this.state;
      return (
        <div id="heroMatch">
          <div id="matchTime">
            <button type="button" className="prevNext" onClick={() => this.updateGameView('prev')}>
              <img src={prev} alt="left arrow" />
            </button>
            <p>{gameInView.date.format('dddd D MMM HH:mmu')}</p>
            <button type="button" className="prevNext" onClick={() => this.updateGameView('next')}>
              <img src={next} alt="right arrow" />
            </button>
          </div>
          <StaticQuery
            query={graphql`
              query {
                allContentfulTeamLogos {
                  edges {
                    node {
                      logos {
                        file {
                          contentType
                          url
                        }
                        title
                        fixed(height: 50) {
                          ...GatsbyContentfulFixed_withWebp
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={data => {
              const homeLogo = data.allContentfulTeamLogos.edges[0].node.logos.filter(
                logo => logo.title === gameInView.tTGUID.slice(0, 8)
              )[0];
              const awayLogo = data.allContentfulTeamLogos.edges[0].node.logos.filter(
                logo => logo.title === gameInView.tUGUID.slice(0, 8)
              )[0];
              return (
                <div id="matchDetails">
                  <Fade left>
                    <div className="team" key={`${gameInView.wedID}home`}>
                      <p className="teamName">{gameInView.tTNaam}</p>
                      {homeLogo &&
                        (homeLogo.file.contentType.includes('svg') ? (
                          <img src={homeLogo.file.url} className="teamImage" alt="oxaco logo" />
                        ) : (
                          <Image fixed={homeLogo.fixed} className="teamImage" />
                        ))}
                      {gameInView.uitslag && (
                        <span className="score">{gameInView.uitslag.split('-')[0]}</span>
                      )}
                    </div>
                  </Fade>
                  <span>vs</span>
                  <Fade right>
                    <div className="team" key={`${gameInView.wedID}away`}>
                      <p className="teamName">{gameInView.tUNaam}</p>
                      {awayLogo &&
                        (awayLogo.file.contentType.includes('svg') ? (
                          <img src={awayLogo.file.url} className="teamImage" alt="oxaco logo" />
                        ) : (
                          <Image fixed={awayLogo.fixed} className="teamImage" />
                        ))}
                      {gameInView.uitslag && (
                        <span className="score">{gameInView.uitslag.split('-')[1]}</span>
                      )}
                    </div>
                  </Fade>
                </div>
              );
            }}
          />
          <p id="location">{gameInView.accNaam}</p>
        </div>
      );
    }
    return null;
  }
}
